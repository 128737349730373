<template>
  <div v-if="subArea.type === 'saat' && markers.length">
    <FilterGroup
      :item-count="markers.length"
      :title="$t('map.general.markers')"
      is-expanded
      text-color="black"
      background-color="rgb(240, 240, 240)"
      icon="icon-location">
      <FilterMarkerGroup
        v-if="passMarkers.length > 0"
        :item-count="passMarkers.length"
        :icon="icon('pass')"
        :title="name('pass')">
        <FilterMarker v-for="marker in passMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="towerMarkers.length > 0"
        :item-count="towerMarkers.length"
        :icon="icon('tower')"
        :title="name('tower')">
        <FilterMarker v-for="marker in towerMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="foodPlaceMarkers.length > 0"
        :item-count="foodPlaceMarkers.length"
        :icon="icon('food_place')"
        :title="name('food_place')">
        <FilterMarker v-for="marker in foodPlaceMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="baitMarkers.length > 0"
        :item-count="baitMarkers.length"
        :icon="icon('bait')"
        :title="name('bait')">
        <FilterMarker v-for="marker in baitMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="gatherPlaceMarkers.length > 0"
        :item-count="gatherPlaceMarkers.length"
        :icon="icon('gathering_place')"
        :title="name('gathering_place')">
        <FilterMarker v-for="marker in gatherPlaceMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="bridgeMarkers.length > 0"
        :item-count="bridgeMarkers.length"
        :icon="icon('bridge')"
        :title="name('bridge')">
        <FilterMarker v-for="marker in bridgeMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="fordMarkers.length > 0"
        :item-count="fordMarkers.length"
        :icon="icon('ford')"
        :title="name('ford')">
        <FilterMarker v-for="marker in fordMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="saltLickMarkers.length > 0"
        :item-count="saltLickMarkers.length"
        :icon="icon('salt_lick')"
        :title="name('salt_lick')">
        <FilterMarker v-for="marker in saltLickMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="barMarkers.length > 0"
        :item-count="barMarkers.length"
        :icon="icon('bar')"
        :title="name('bar')">
        <FilterMarker v-for="marker in barMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="camMarkers.length > 0"
        :item-count="camMarkers.length"
        :icon="icon('cam')"
        :title="name('cam')">
        <FilterMarker v-for="marker in camMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="burrowMarkers.length > 0"
        :item-count="burrowMarkers.length"
        :icon="icon('burrow')"
        :title="name('burrow')">
        <FilterMarker v-for="marker in burrowMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="parkingMarkers.length > 0"
        :item-count="parkingMarkers.length"
        :icon="icon('parking')"
        :title="name('parking')">
        <FilterMarker v-for="marker in parkingMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="otherMarkers.length > 0"
        :item-count="otherMarkers.length"
        :icon="icon('other')"
        :title="name('other')">
        <FilterMarker v-for="marker in otherMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>

      <FilterMarkerGroup
        v-if="mammalMarkers.length > 0"
        :item-count="mammalMarkers.length"
        :icon="icon('mammal')"
        :title="name('mammal')">
        <FilterMarker v-for="marker in mammalMarkers" :key="marker.id" :marker="marker" />
      </FilterMarkerGroup>
    </FilterGroup>
  </div>
</template>

<script>
import { getMarkerTypeIconUrl, getMarkerNameFromType } from '@/api/map/markers/markers'

import FilterGroup from './filterPanel/FilterGroup.vue'
import FilterMarkerGroup from './filterPanel/FilterMarkerGroup.vue'
import FilterMarker from './filterPanel/FilterMarker.vue'

export default {
  components: {
    FilterGroup,
    FilterMarkerGroup,
    FilterMarker
  },

  computed: {
    subArea () { return this.$store.getters['map/subAreas/selected'] },
    markers () { return this.$store.getters['map/getMarkersInSubArea'](this.subArea) },

    passMarkers () { return this.markers.filter(marker => marker.type === 'pass') },
    towerMarkers () { return this.markers.filter(marker => marker.type === 'tower') },
    foodPlaceMarkers () { return this.markers.filter(marker => marker.type === 'food_place') },
    baitMarkers () { return this.markers.filter(marker => marker.type === 'bait') },
    gatherPlaceMarkers () { return this.markers.filter(marker => marker.type === 'gathering_place') },
    bridgeMarkers () { return this.markers.filter(marker => marker.type === 'bridge') },
    fordMarkers () { return this.markers.filter(marker => marker.type === 'ford') },
    saltLickMarkers () { return this.markers.filter(marker => marker.type === 'salt_lick') },
    barMarkers () { return this.markers.filter(marker => marker.type === 'bar') },
    camMarkers () { return this.markers.filter(marker => marker.type === 'cam') },
    burrowMarkers () { return this.markers.filter(marker => marker.type === 'burrow') },
    parkingMarkers (state) { return this.markers.filter(marker => marker.type === 'parking') },
    otherMarkers (state) { return this.markers.filter(marker => marker.type === 'other') },
    mammalMarkers () { return this.markers.filter(marker => marker.type === 'mammal') }
  },

  methods: {
    icon (type) {
      return getMarkerTypeIconUrl(type)
    },

    name (type) {
      return getMarkerNameFromType(type)
    }
  }
}
</script>
