<template>
  <Panel :isActive="isActive">
    <PanelTitle
      v-if="subArea"
      :data="subArea"
      :areaInHa="areaInHa"
      @close="cancel">
    </PanelTitle>

    <PanelContent scrollable>
      <Fragment v-if="subArea && !subArea.editable">
        <div class="field push-top">
          <Button
            class="is-fullwidth is-light"
            icon="icon-edit"
            :title="$t('general.edit')"
            @click="edit">
          </Button>
        </div>

        <SubAreaPanelFilter />
      </Fragment>

      <Fragment v-if="subArea && subArea.editable">
        <form class="form" @submit.prevent="update">
          <FormField :label="$t('general.name')">
            <TextInputField
              v-model="subArea.name"
              :disabled="isLoading"
              :placeholder="$t('general.name')">
            </TextInputField>
          </FormField>

          <FormField v-if="subArea.id && createdBy !== null" :control="false">
            <small>{{ $t('map.general.createdAt', { date: createdAt, name: createdBy.getFullName() }) }}</small>
          </FormField>

          <ButtonGroup>
            <Button
              type="primary"
              :loading="isSaving"
              :disabled="isRemoving"
              :title="$t('general.save')"
              @click="update">
            </Button>

            <Button
              :disabled="isLoading"
              :title="$t('general.cancel')"
              @click="cancel">
            </Button>

            <Button
              v-if="subArea.id"
              type="danger"
              :loading="isRemoving"
              :disabled="isSaving"
              icon="icon-trash"
              @click="confirmRemove">
            </Button>
          </ButtonGroup>
        </form>
      </Fragment>
    </PanelContent>
  </Panel>
</template>

<script>
import { eventManager } from '@/main'
import SubAreaPanelFilter from './SubAreaPanelFilter.vue'

const STATE_DEFAULT = ''
const STATE_REMOVING = 'removing'
const STATE_SAVING = 'saving'

export default {
  components: {
    SubAreaPanelFilter
  },

  data () {
    return {
      huntAreaId: this.$route.params.huntAreaId,
      subAreaId: this.$route.params.subAreaId,
      state: STATE_DEFAULT,
      createdBy: null,
      original: {
        name: null,
        borderCoordinates: []
      }
    }
  },

  computed: {
    subArea: {
      get () { return this.$store.getters['map/subAreas/selected'] },
      set (subArea) { return this.$store.dispatch('map/subAreas/select', subArea) }
    },

    isActive () {
      return this.subArea !== undefined
    },

    editable: {
      get () { return this.subArea ? this.subArea.editable : false },
      set (state) { this.subArea.editable = state }
    },

    isLoading () {
      return this.isSaving || this.isRemoving
    },

    isSaving () {
      return this.state === STATE_SAVING
    },

    isRemoving () {
      return this.state === STATE_REMOVING
    },

    areaInHa () {
      return Number((this.subArea.computeArea() / 10000).toFixed(0))
    },

    createdAt () {
      return this.$dayjs(this.subArea.createdAt).format('D MMMM YYYY, HH:mm')
    }
  },

  methods: {
    close () {
      this.$store.dispatch('map/subAreas/select', null)
      this.$router.push(`/area/${this.huntAreaId}/map`)
    },

    async edit () {
      this.original.name = this.subArea.name
      this.original.borderCoordinates = this.subArea.borderCoordinates
      this.subArea.editable = true

      if (this.subArea.createdBy) {
        // This code must be executed last to prevent any delays in the UI.
        // On failure we just fail silently and don't show the label.
        this.createdBy = await this.$store.dispatch('user/read', this.subArea.createdBy)
      }
    },

    cancel () {
      if (!this.subArea) return

      if (this.editable) {
        this.subArea.name = this.original.name
        this.subArea.borderCoordinates = this.original.borderCoordinates
        this.subArea.editable = false
        this.state = STATE_DEFAULT
      }

      this.close()
    },

    async update () {
      this.state = STATE_SAVING

      try {
        await this.$store.dispatch('map/subAreas/update', {
          huntAreaId: this.huntAreaId,
          subArea: this.subArea
        })

        this.subArea.editable = false
        this.close()
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      } finally {
        this.state = STATE_DEFAULT
      }
    },

    async confirmRemove () {
      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.remove'),
          message: this.$t('general.reallyRemove', { name: this.subArea.name }),
          ok: this.$t('general.remove')
        })

        if (response.ok) {
          await this.remove()
          this.cancel()
        }
      } catch (error) {
        this.$notification.danger(this.$t('general.saveFailedErrorMessage'))
      }
    },

    async remove () {
      this.state = STATE_REMOVING

      try {
        await this.$store.dispatch('map/subAreas/delete', {
          huntAreaId: this.huntAreaId,
          subArea: this.subArea
        })

        if (this.subArea) {
          this.subArea.visible = false
        }

        eventManager.$emit('boundary:fitBounds')
      } finally {
        this.state = STATE_DEFAULT
      }
    }
  }
}
</script>
